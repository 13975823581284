import { ThemeOptions } from '@mui/material';

const defaultTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1650, // all default except xl
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          scrollbarWidth: 'thin',
          backgroundColor: theme.palette.primary.main,
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          scrollbarWidth: 'thin',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '0',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          background: 'unset',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&:hover': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
          color: ownerState.disabled
            ? theme.palette.action.disabled
            : theme.palette.primary.main,
          backgroundColor: ownerState.disabled
            ? theme.palette.action.disabledBackground
            : theme.palette.secondary.main,
          borderColor: ownerState.disabled
            ? theme.palette.action.disabledBackground
            : theme.palette.secondary.main,
          borderRadius: 9999,
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.primary.light,
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: ({ ownerState, theme }) => ({
          backgroundColor: ownerState.checked
            ? theme.palette.secondary.main
            : theme.palette.text.primary,
        }),
        track: ({ theme }) => ({
          '.Mui-checked.Mui-checked + &': {
            opacity: 0.8,
            backgroundColor: theme.palette.secondary.main,
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
          },
          '&.Mui-disabled': {
            backgroundColor: 'grey',
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.main,
          '&:hover': {
            color: theme.palette.secondary.dark,
          },
        }),
        valueLabel: ({ theme }) => ({
          color: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: () => ({
          color: 'cyan',
          '&:hover': {
            color: 'cyan',
          },
        }),
      },
    },
  },

  typography: {
    fontFamily: 'Titillium Web, sans-serif',
    button: {
      textTransform: 'none',
    },
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '22px',
    },
    h6: {
      fontWeight: 'bold',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#03152d',
      dark: '#01070f',
      light: '#062652',
    },
    secondary: {
      main: '#4bfa91',
      dark: '#1f7040',
      light: '#afedc8',
    },
    background: {
      default: '#49454f',
      paper: '#231f20',
    },
    text: {
      primary: '#fff',
      secondary: '#bbb',
    },
    action: {
      disabled: '#a8b0bf',
      disabledBackground: '#223147',
    },
  },
};

export default defaultTheme;
