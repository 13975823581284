/* eslint-disable react/destructuring-assignment */
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import copy from 'clipboard-copy';
import { LineString, Position } from 'geojson';
import mapboxgl, { MapMouseEvent } from 'mapbox-gl';
import { Privileges } from '../hooks/access-control/privileges';
import { EMenuItems, setSelectedOption } from '../main-menu/menu.slice';
import { setInitialFormValues } from '../maritime-menu-options/areas-panel/drawings.slice';
import getDrawStyle from '../maritime-menu-options/areas-panel/mapbox-draw-styles';
import { DrawingShape, isDrawingCircleFeature } from '../models/drawings.model';
import { addHistoryItem } from '../nav-history.slice';
import { setCreateDrawingPanelVisible } from '../state/drawings/drawings.slice';
import store from '../store';
import ExtendDrawBar from '../utils/extend-draw-bar';
import GeoHelper from '../utils/geo-helpers.utils';
import DragCircleMode from '../utils/mapbox-gl-draw-circle';
import {
  addEndMarkerSourceAndLayer,
  pointFeatureCollection,
} from '../utils/mapbox-helper';
import { neatDisplayNumber } from '../utils/text-formatting.utils';
import './map-controls.utils.scss';
import MapLayer from './map-layer-manager/map-layer.enum';
import { INITIAL_MAP_STATE, setDrawType } from './map.slice';
import MapHelpers from './map.utils';

// prevent dragging the whole feature, as this would not be reflected in the form
StaticMode.onDrag = () => {};
const { modes } = MapboxDraw;
modes.simple_select.onDrag = () => {};

export const themedDraw = new MapboxDraw({
  displayControlsDefault: false,
  styles: getDrawStyle(),
  modes: {
    ...MapboxDraw.modes,
    // If custom mode name does not include substring 'draw' it will not render the cursor as 'add'
    draw_circle: DragCircleMode,
    static: StaticMode,
  },
  controls: {
    point: true,
    line_string: true,
    combine_features: false,
    uncombine_features: false,
    polygon: true,
  },
});

function ensureLongitudeInRange(decimal: number): number {
  let cleanedDecimal = decimal;
  while (cleanedDecimal > 180) {
    cleanedDecimal -= 360;
  }
  while (cleanedDecimal < -180) {
    cleanedDecimal += 360;
  }
  return cleanedDecimal;
}

export function decimalToDMS(
  decimal: number,
  latLng: string,
  normalise: boolean = false
): string {
  const decimalToProcess = normalise
    ? ensureLongitudeInRange(decimal)
    : decimal;

  const absDecimal = Math.abs(decimalToProcess);
  let degrees = Math.floor(absDecimal);
  const decimalMinutes = (absDecimal - degrees) * 60;
  let minutes = Math.floor(decimalMinutes);
  let seconds = (decimalMinutes - minutes) * 60;
  // 50° 60' 60" is not valid, so we need to round up (to 51° 01' 00")
  if (seconds >= 59.5) {
    minutes += 1;
    seconds = 0;
  }
  if (minutes >= 59.5) {
    degrees += 1;
    minutes = 0;
  }

  let direction: string = '0';

  if (latLng === 'latitude') {
    direction = decimalToProcess < 0 ? 'S' : 'N';
  } else if (latLng === 'longitude') {
    direction = decimalToProcess < 0 ? 'W' : 'E';
  }

  const zeroPrefixDegrees = String(degrees).padStart(2, '0');
  const zeroPrefixMinutes = String(minutes).padStart(2, '0');
  const zeroPrefixSeconds = String(seconds.toFixed(0)).padStart(2, '0');

  return `${zeroPrefixDegrees}° ${zeroPrefixMinutes}' ${zeroPrefixSeconds}" ${direction}`;
}

export function convertCoordsToDMS(formPoints: Position[]) {
  return formPoints.map((coord) => [
    decimalToDMS(coord[0], 'longitude'),
    decimalToDMS(coord[1], 'latitude'),
  ]);
}

export function latLngDecimalToDMS(
  latDecimal: number,
  lngDecimal: number
): string {
  const latDMS: string = decimalToDMS(latDecimal, 'latitude');
  const lngDMS: string = decimalToDMS(lngDecimal, 'longitude');

  return `${latDMS} ${lngDMS}`;
}

export function DMSToDecimal(dmsString: string): number | null {
  const dmsPattern = /(\d+)°\s(\d+)'\s(\d+)"\s([NnEeSsWw])/;
  const match = dmsString.match(dmsPattern);
  if (match) {
    const degrees = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const seconds = parseInt(match[3], 10);
    const direction = match[4].toUpperCase();

    if (degrees > 180 || degrees < -180) {
      return null;
    }

    let decimal = degrees + minutes / 60 + seconds / 3600;

    if (direction === 'S' || direction === 'W') {
      decimal = -decimal; // Flip the sign for South (S) and West (W)
    }
    return decimal;
  }
  return null;
}

export const updatePopupContent = (
  mode: string,
  tooltipsPopup: mapboxgl.Popup
) => {
  switch (mode) {
    case 'draw_line_string':
      tooltipsPopup.setHTML('Click to start drawing a line');
      break;
    case 'draw_polygon':
      tooltipsPopup.setHTML('Click to start drawing a polygon');
      break;
    case 'draw_circle':
      tooltipsPopup.setHTML('Click and drag to draw a circle');
      break;
    case 'draw_point':
      tooltipsPopup.setHTML('Click to place a marker');
      break;
    default:
      tooltipsPopup.setHTML('Click to start drawing');
  }
};

export const movePopupWithMouse = (
  e: MapMouseEvent,
  tooltipsPopup: mapboxgl.Popup
) => {
  const { lng, lat } = e.lngLat;
  const map = MapHelpers.getMapInstance();
  tooltipsPopup.setLngLat([lng, lat]);
  tooltipsPopup.addTo(map);
};

export const removeToolTipsPopup = (
  tooltipsPopup: mapboxgl.Popup,
  handleMouseMove: (e: MapMouseEvent) => void
) => {
  tooltipsPopup.remove();
  MapHelpers.removeMapEventListener('mousemove', handleMouseMove);
};

export const addDrawTipsPopup = (draw: MapboxDraw) => {
  const tooltipsPopup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    anchor: 'left',
    className: 'draw-tips',
  });
  const map = MapHelpers.getMapInstance();
  if (!MapHelpers.getSource(MapLayer.DRAW_MEASUREMENT_TEXT)) {
    MapHelpers.addMapEventListener('style.load', null, () =>
      addEndMarkerSourceAndLayer(MapLayer.DRAW_MEASUREMENT_TEXT)
    );
  }

  let mousePoint: [number, number] = [0, 0];
  let lastClickEvent: (mapboxgl.MapMouseEvent & mapboxgl.EventData) | null =
    null;
  const handleMouseMove = (e: MapMouseEvent) => {
    const lastClickCoordinates = lastClickEvent?.lngLat.toArray() || [];
    if (lastClickCoordinates) {
      mousePoint = [e.lngLat.lng, e.lngLat.lat];
      const distanceInNm = GeoHelper.calculateDistanceBetweenPoints(
        [lastClickCoordinates[0], lastClickCoordinates[1]] as [number, number],
        mousePoint,
        'nauticalmiles'
      );

      const measuementDisplayNumber = neatDisplayNumber(distanceInNm);

      GeoHelper.setMapboxGeoJSONSourceData(
        MapLayer.DRAW_MEASUREMENT_TEXT,
        pointFeatureCollection(
          [mousePoint],
          [measuementDisplayNumber ? `${measuementDisplayNumber} NM` : '']
        )
      );
    }

    movePopupWithMouse(e, tooltipsPopup);
  };

  const removeTooltipsAndEvent = () => {
    removeToolTipsPopup(tooltipsPopup, handleMouseMove);
    GeoHelper.setMapboxGeoJSONSourceData(
      MapLayer.DRAW_MEASUREMENT_TEXT,
      pointFeatureCollection([], [])
    );
    lastClickEvent = null;
  };

  MapHelpers.addMapEventListener('draw.modechange', null, (drawToolObj) => {
    if (drawToolObj.mode === 'simple_select' && MapHelpers.getStyleValid()) {
      removeTooltipsAndEvent();
    } else {
      MapHelpers.addMapEventListener('mousemove', null, handleMouseMove);
      updatePopupContent(drawToolObj.mode, tooltipsPopup);
      tooltipsPopup.addTo(map);
    }
  });

  // circle popup created with click event because it's a custom drawing mode unlike line and polygon
  const circleDrawButton = document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_circle'
  ) as HTMLButtonElement | null;
  if (circleDrawButton) {
    circleDrawButton.addEventListener('click', () => {
      MapHelpers.addMapEventListener('mousemove', null, handleMouseMove);
      tooltipsPopup.setHTML('Click and drag to draw a circle');
      tooltipsPopup.addTo(map);
    });
  }

  MapHelpers.addMapEventListener('click', null, (e) => {
    const drawMode = draw.getMode();
    /* eslint-disable @typescript-eslint/no-unused-vars */
    lastClickEvent = e;
    if (drawMode === 'draw_line_string') {
      const allDrawings = draw.getAll();
      const lineStrings = allDrawings.features.filter(
        (drawing) => drawing.geometry.type === 'LineString'
      );
      const createdLineString = lineStrings[lineStrings.length - 1];
      const coordinates = (createdLineString?.geometry as LineString)
        ?.coordinates;

      const popupText =
        coordinates.length > 0 && coordinates.length < 3
          ? 'Click to continue drawing line'
          : 'Click the last point to finish';
      tooltipsPopup.setHTML(popupText);
    } else if (drawMode === 'draw_polygon') {
      tooltipsPopup.setHTML('Click the first or last point to finish');
    }
  });

  // remove tooltips when a draw feature is created
  MapHelpers.addMapEventListener('draw.create', null, () => {
    // Flag that the drawing session has ended in redux
    store.dispatch(setDrawType('draw_end'));
    removeTooltipsAndEvent();
  });
};

export const setMapDrawControlsState = (privileges: Privileges) => {
  const circleDrawButton = document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_circle'
  ) as HTMLButtonElement | null;
  if (circleDrawButton) {
    circleDrawButton.disabled = !privileges.canAccessDrawings;
    circleDrawButton.title = privileges.canAccessDrawings
      ? 'Circle Tool'
      : 'You require a NorthStandard account to access these features.';
  }

  const lineDrawButton = document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line'
  ) as HTMLButtonElement | null;
  if (lineDrawButton) {
    lineDrawButton.disabled = !privileges.canAccessDrawings;
    lineDrawButton.title = privileges.canAccessDrawings
      ? 'Line Tool'
      : 'You require a NorthStandard account to access these features.';
  }

  const polygonDrawButton = document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon'
  ) as HTMLButtonElement | null;
  if (polygonDrawButton) {
    polygonDrawButton.disabled = !privileges.canAccessDrawings;
    polygonDrawButton.title = privileges.canAccessDrawings
      ? 'Polygon Tool'
      : 'You require a NorthStandard account to access these features.';
  }

  const pointDrawButton = document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_point'
  ) as HTMLButtonElement | null;
  if (pointDrawButton) {
    pointDrawButton.disabled = !privileges.canAccessDrawings;
    pointDrawButton.title = privileges.canAccessDrawings
      ? 'Marker Tool'
      : 'You require a NorthStandard account to access these features.';
  }
};

export const addMapDrawControls = (draw: MapboxDraw) => {
  const extendedDrawBar = new ExtendDrawBar({
    draw,
    buttons: [
      {
        action: () => draw.changeMode('draw_circle'),
        classes: ['mapbox-gl-draw_circle'],
        faIcon: faCircle,
        iconStrokeWidth: 35,
        iconWidth: 30,
        iconHeight: 20,
        title: 'Circle Tool',
      },
    ],
  });

  MapHelpers.addControl(extendedDrawBar, 'bottom-right');

  MapHelpers.addMapEventListener(
    'draw.create',
    null,
    (obj: MapboxDraw.DrawCreateEvent) => {
      const currentDrawingId = obj.features[0].id;
      if (draw && typeof draw.getAll === 'function') {
        const allDrawings = draw.getAll();
        if (allDrawings && allDrawings.features) {
          allDrawings.features.forEach((feature) => {
            if (feature.id !== currentDrawingId) {
              draw.delete(feature.id as string);
            }
          });
        }
      }

      // The default draw.create event is not fired when the app is in route mode
      const { drawType } = store.getState().map;
      if (drawType === 'route') {
        return;
      }

      const drawnFeature = obj.features[0];

      if (isDrawingCircleFeature(drawnFeature)) {
        store.dispatch(
          setInitialFormValues({
            common: {
              shape: DrawingShape.Circle,
              shapeId: drawnFeature.id as string,
              edited: true,
            },
            [DrawingShape.Circle]: {
              centrePointInputLong:
                drawnFeature.properties.center[0].toFixed(4),
              centrePointInputLat: drawnFeature.properties.center[1].toFixed(4),
              radiusInNauticalMilesInput:
                drawnFeature.properties.radiusInNm?.toString(),
            },
          })
        );
      }

      if (
        !isDrawingCircleFeature(drawnFeature) &&
        drawnFeature.geometry.type === 'Polygon'
      ) {
        const convertedCoordinates: [string, string][] =
          drawnFeature.geometry.coordinates[0].map((coord: number[]) => {
            const [lng, lat] = coord;
            return [
              decimalToDMS(lng, 'longitude', true),
              decimalToDMS(lat, 'latitude', true),
            ];
          });

        store.dispatch(
          setInitialFormValues({
            common: {
              shape: DrawingShape.Polygon,
              shapeId: drawnFeature.id as string,
              edited: true,
            },
            [DrawingShape.Polygon]: {
              coordinates: convertedCoordinates,
            },
          })
        );
      }
      if (
        !isDrawingCircleFeature(drawnFeature) &&
        drawnFeature.geometry.type === 'LineString'
      ) {
        const convertedCoordinates: [string, string][] =
          drawnFeature.geometry.coordinates.map((coord: number[]) => {
            const [lng, lat] = coord;
            return [
              decimalToDMS(lng, 'longitude', true),
              decimalToDMS(lat, 'latitude', true),
            ];
          });
        store.dispatch(
          setInitialFormValues({
            common: {
              shape: DrawingShape.Line,
              shapeId: drawnFeature.id as string,
              edited: true,
            },
            [DrawingShape.Line]: {
              coordinates: convertedCoordinates,
            },
          })
        );
      }

      if (
        !isDrawingCircleFeature(drawnFeature) &&
        drawnFeature.geometry.type === 'Point'
      ) {
        store.dispatch(
          setInitialFormValues({
            common: {
              shape: DrawingShape.Point,
              shapeId: drawnFeature.id as string,
              edited: true,
            },
            [DrawingShape.Point]: {
              inputLong: decimalToDMS(
                drawnFeature.geometry.coordinates[0],
                'longitude',
                true
              ),
              inputLat: decimalToDMS(
                drawnFeature.geometry.coordinates[1],
                'latitude',
                true
              ),
            },
          })
        );
      }

      store.dispatch(setSelectedOption(EMenuItems.DRAWINGS));
      store.dispatch(addHistoryItem({ type: 'drawings' }));
      store.dispatch(setCreateDrawingPanelVisible(true));
    }
  );
};

export const addSaveLatLongButton = (draw: MapboxDraw) => {
  const mapboxControls = document.querySelector('.mapboxgl-ctrl-bottom-right');
  const mapboxControlsGroup = mapboxControls?.firstChild;
  const map = MapHelpers.getMapInstance();
  const createSaveLatLongButton = (
    className: string,
    onClick: Function = () => {}
  ) => {
    const controlButton = document.createElement('button');

    if (controlButton) {
      controlButton.className = className;
      controlButton.ariaLabel = 'Save Coordinate';
      controlButton.name = 'Save Coordinate';
      controlButton.title = 'Save Coordinate';
      controlButton.addEventListener('click', () => {
        onClick();
      });
    }

    return controlButton;
  };

  const saveLatLongButton = createSaveLatLongButton(
    'mapbox-gl-latlng_ctrl-btn',
    () => {
      draw.changeMode('static');
      const tooltip = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 25,
        anchor: 'left',
        className: 'draw-tips',
      });

      const handleMouseMove = (e: mapboxgl.MapMouseEvent) => {
        tooltip
          .setLngLat(e.lngLat)
          .setText('Click to save DMS location')
          .addTo(map);
      };

      const handleMouseClick = (e: mapboxgl.MapMouseEvent) => {
        copy(latLngDecimalToDMS(e.lngLat.lat, e.lngLat.lng));

        tooltip.remove();
        MapHelpers.removeMapEventListener('mousemove', handleMouseMove);
        MapHelpers.removeMapEventListener('click', handleMouseClick);

        const locationSavedPopup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: true,
          offset: 25,
          className: 'draw-tips',
        }).setText(`Copied to clipboard`);

        draw.changeMode('simple_select');

        locationSavedPopup.setLngLat(e.lngLat).addTo(map);
        setTimeout(() => {
          locationSavedPopup.remove();
        }, 2000);
      };

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          tooltip.remove();
          MapHelpers.removeMapEventListener('mousemove', handleMouseMove);
          MapHelpers.removeMapEventListener('click', handleMouseClick);
          document.removeEventListener('keydown', handleKeyDown);
          draw.changeMode('simple_select');
        }
      };

      MapHelpers.addMapEventListener('draw.modechange', null, (drawToolObj) => {
        if (drawToolObj.mode !== 'static') {
          tooltip.remove();
          MapHelpers.removeMapEventListener('mousemove', handleMouseMove);
          MapHelpers.removeMapEventListener('click', handleMouseClick);
          document.removeEventListener('keydown', handleKeyDown);
        }
      });

      document.addEventListener('keydown', handleKeyDown);
      MapHelpers.addMapEventListener('mousemove', null, handleMouseMove);
      MapHelpers.addMapEventListener('click', null, handleMouseClick);
    }
  );

  mapboxControlsGroup?.insertBefore(
    saveLatLongButton!,
    mapboxControlsGroup.firstChild
  );
};

export const addCustomMapControls = (draw: MapboxDraw) => {
  const mapboxControls = document.querySelector('.mapboxgl-ctrl-bottom-right');
  const mapboxControlsGroup = mapboxControls?.firstChild;

  const createMapboxControlButton = (
    className: string,
    onClick: Function = () => {}
  ) => {
    const controlButton = document.createElement('button');

    if (controlButton) {
      controlButton.className = className;
      controlButton.title = 'Home';
      controlButton.addEventListener('click', () => {
        onClick();
      });
    }

    return controlButton;
  };
  const homeButton = createMapboxControlButton('mapboxgl-ctrl-home', () => {
    MapHelpers.flyTo({
      center: INITIAL_MAP_STATE.mapExtent.center,
      zoom: INITIAL_MAP_STATE.mapExtent.zoom,
    });
  });

  mapboxControlsGroup?.insertBefore(
    homeButton!,
    mapboxControlsGroup.firstChild
  );
  addSaveLatLongButton(draw);
  addMapDrawControls(draw);
  addDrawTipsPopup(draw);
};
