/* eslint-disable react/jsx-props-no-spreading */
import {
  autoUpdate,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useMobile } from '../../hooks';
import { EDossiers, setSelectedDossier } from '../../main-menu/menu.slice';
import SecondaryPane from '../../main-menu/secondary-pane/secondary-pane';
import CommonCloseButton from '../common-close-button/common-close-button';

interface CommonDossierContainerProps {
  deselectDossier: () => void;
  children: JSX.Element | undefined;
}

function CommonDossierContainer({
  deselectDossier,
  children,
}: CommonDossierContainerProps) {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<Element | null>();
  const isMobile = useMobile();
  const dispatch = useAppDispatch();
  const secondaryMenuOpen = useAppSelector(
    (state) => state.menu.secondaryMenuOpen
  );
  const containerHeight = useAppSelector(
    (state) => state.timeline.containerHeight
  );

  useEffect(() => {
    // current assumption is that the common dossier should always be anchored
    // to the secondary pane
    setAnchor(document.querySelector('[data-testid=secondary-pane-container]'));
  });

  const { x, strategy, refs, context } = useFloating({
    elements: { reference: anchor },
    open,
    onOpenChange: setOpen,
    placement: 'right-start',
    middleware: [offset(10)],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getFloatingProps } = useInteractions([click, dismiss, role]);

  return secondaryMenuOpen && !isMobile ? (
    <Box
      className="popoverContainer"
      sx={{
        width: '360px',
        borderRadius: '1rem',
        position: strategy,
        top: '0.4rem',
        left: x ?? 0,
        bgcolor: 'primary.main',
        overflow: 'hidden',
        maxHeight: `calc(100vh - var(--news-ticker-height) - ${containerHeight}px - 1rem) !important`,
      }}
      ref={refs.setFloating}
      {...getFloatingProps()}
    >
      <CommonCloseButton
        onClick={() => {
          dispatch(setSelectedDossier(EDossiers.NOTHING));
          deselectDossier();
        }}
        testId="SpecificCloseIcon"
      />
      {children}
    </Box>
  ) : (
    <SecondaryPane component={children!} isSpecific />
  );
}

export default CommonDossierContainer;
