/* eslint-disable react/jsx-no-useless-fragment */
import { StackedLineChartRounded, WbSunny } from '@mui/icons-material';
import AnimationIcon from '@mui/icons-material/Animation';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import LayerToggle from '../../common-components/layer-toggle/layer-toggle';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useAccessControl from '../../hooks/access-control/useAccessControl';
import { MapGroupLayer } from '../../map/map-layer-manager/map-layer.enum';
import {
  ToolsPanelViewState,
  setToolsPanelViewState,
} from './tools-panel.slice';

function ToolsMenu() {
  const dispatch = useAppDispatch();
  const { rangeRings } = useAppSelector((state) => state.toolsPanel);
  const { canAccessNearbyVessels, canAccessWeather, canAccessShippingLanes } =
    useAccessControl();

  return (
    <List data-testid="tools-menu">
      <ListItem disablePadding>
        <ListItemButton
          onClick={() =>
            dispatch(setToolsPanelViewState(ToolsPanelViewState.MEASURING_TOOL))
          }
        >
          <ListItemIcon
            sx={{
              color: 'secondary.main',
            }}
          >
            <SquareFootIcon />
          </ListItemIcon>
          Measuring Tool
        </ListItemButton>
      </ListItem>
      {canAccessNearbyVessels && (
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              dispatch(
                setToolsPanelViewState(ToolsPanelViewState.NEARBY_VESSELS)
              )
            }
          >
            <ListItemIcon
              sx={{
                color: 'secondary.main',
              }}
            >
              <ShareLocationIcon />
            </ListItemIcon>
            Nearby Vessels
          </ListItemButton>
        </ListItem>
      )}
      <ListItem
        disablePadding
        secondaryAction={
          <>
            {rangeRings.points.length > 0 && (
              <LayerToggle
                layerGroups={[MapGroupLayer.RANGE_RINGS]}
                layers={[]}
              />
            )}
          </>
        }
      >
        <ListItemButton
          onClick={() =>
            dispatch(setToolsPanelViewState(ToolsPanelViewState.RANGE_RINGS))
          }
        >
          <ListItemIcon
            sx={{
              color: 'secondary.main',
            }}
          >
            <AnimationIcon />
          </ListItemIcon>
          Range Rings
        </ListItemButton>
      </ListItem>
      {canAccessWeather && (
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              dispatch(setToolsPanelViewState(ToolsPanelViewState.WEATHER))
            }
          >
            <ListItemIcon
              sx={{
                color: 'secondary.main',
              }}
            >
              <WbSunny />
            </ListItemIcon>
            Weather
          </ListItemButton>
        </ListItem>
      )}
      {canAccessShippingLanes && (
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              dispatch(
                setToolsPanelViewState(ToolsPanelViewState.SHIPPING_LANES)
              )
            }
          >
            <ListItemIcon
              sx={{
                color: 'secondary.main',
              }}
            >
              <StackedLineChartRounded />
            </ListItemIcon>
            Shipping Lanes
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );
}

export default ToolsMenu;
