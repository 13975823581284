import { LayerToggleProps } from '../common-components/layer-toggle/layer-toggle';
import { useAppSelector } from '../hooks';
import setCorrespondentsFeatures from '../map/map-layer-manager/correspondents-utils/set-correspondents-features';
import setFirstCallFeatures from '../map/map-layer-manager/first-call-utils/set-first-call-features';
import setIndustryNewsFeatures from '../map/map-layer-manager/industry-news-utils/set-industry-news-features';
import MapLayer, {
  MapGroupLayer,
  MapGroupLayers,
} from '../map/map-layer-manager/map-layer.enum';
import setMaritimeAreasFeatures from '../map/map-layer-manager/maritime-areas-utils/set-maritime-areas-features';
import setPortFeatures from '../map/map-layer-manager/port-utils/set-port-features';
import MapHelpers from '../map/map.utils';
import { EMenuItems } from './menu.slice';

function isLayerSourceEmpty(source: string) {
  if (MapHelpers.getLayer(source)) {
    const features = MapHelpers.querySourceFeatures(source);
    if (features.length === 0) {
      return true;
    }
    return false;
  }
  return true;
}

function useMainMenuLayerConfig() {
  const incidents = useAppSelector((state) => state.incidents.incidents);
  const ports = useAppSelector((state) => state.ports.ports);
  const correspondents = useAppSelector(
    (state) => state.correspondents.correspondents
  );
  const areas = useAppSelector((state) => state.riMaritimeAreas.areas);
  const routes = useAppSelector((state) => state.routes.routes);
  const industryNews = useAppSelector(
    (state) => state.industryNews.industryNews
  );
  const boundaries = useAppSelector((state) => state.boundaries.boundaries);
  const historicVesselPoints = useAppSelector(
    (state) => state.historyPanel.historicVesselPoints
  );
  const drawings = useAppSelector((state) => state.drawings.drawings);
  const rangeRings = useAppSelector((state) => state.toolsPanel.rangeRings);
  const firstCallPorts = useAppSelector(
    (state) => state.firstCallPorts.firstCallPorts
  );

  const layerConfig: Record<string, LayerToggleProps> = {
    [EMenuItems.HISTORY]: {
      layers: [],
      layerGroups: [MapGroupLayer.HISTORY],
      disabled:
        historicVesselPoints === null || historicVesselPoints.length === 0,
    },
    [EMenuItems.MAPS]: {
      layers: [MapLayer.OSM_SEA],
      layerGroups: [],
      disabled: true,
    },
    [EMenuItems.INCIDENTS]: {
      layers: MapGroupLayers.INCIDENTS,
      layerGroups: [],
      disabled: incidents === null || incidents.allIds.length === 0,
    },
    [EMenuItems.BOUNDARIES]: {
      layers: [],
      layerGroups: [MapGroupLayer.BOUNDARIES_GROUP],
      disabled: boundaries === null || boundaries.length === 0,
    },
    [EMenuItems.PORTS]: {
      layers: [
        MapLayer.PORTS,
        MapLayer.PORT_CLUSTERS,
        MapLayer.PORT_CLUSTER_COUNT,
      ],
      layerGroups: [],
      disabled: ports === null || ports.length === 0,
      onToggleOn: () => {
        if (ports && isLayerSourceEmpty(MapLayer.PORTS)) {
          setPortFeatures(MapLayer.PORTS, ports);
        }
      },
    },
    [EMenuItems.DRAWINGS]: {
      layers: [],
      layerGroups: [MapGroupLayer.DRAWINGS],
      disabled: !drawings?.length,
    },
    [EMenuItems.ROUTES]: {
      layers: [],
      layerGroups: [MapGroupLayer.ROUTES],
      disabled: routes === null,
    },
    [EMenuItems.RI_MARITIME_AREAS]: {
      layers: [MapLayer.RI_MARITIME_AREAS],
      layerGroups: [],
      disabled: areas === null || areas.length === 0,
      onToggleOn: () => {
        if (areas && isLayerSourceEmpty(MapLayer.RI_MARITIME_AREAS)) {
          setMaritimeAreasFeatures(MapLayer.RI_MARITIME_AREAS, areas);
        }
      },
    },
    [EMenuItems.FIRST_CALL_PORTS]: {
      layers: [
        MapLayer.FIRST_CALL_CLUSTERS,
        MapLayer.FIRST_CALL_CLUSTER_COUNT,
        MapLayer.FIRST_CALL_PORTS,
      ],
      layerGroups: [],
      disabled: firstCallPorts === null,
      onToggleOn: () => {
        if (firstCallPorts && isLayerSourceEmpty(MapLayer.FIRST_CALL_PORTS)) {
          setFirstCallFeatures(firstCallPorts);
        }
      },
    },
    [EMenuItems.CORRESPONDENTS]: {
      layers: [
        MapLayer.CORRESPONDENTS,
        MapLayer.CORRESPONDENT_CLUSTERS,
        MapLayer.CORRESPONDENT_CLUSTER_COUNT,
      ],
      layerGroups: [],
      onToggleOn: () => {
        if (correspondents && isLayerSourceEmpty(MapLayer.CORRESPONDENTS)) {
          setCorrespondentsFeatures(MapLayer.CORRESPONDENTS, correspondents);
        }
      },
      disabled: correspondents === null,
    },
    [EMenuItems.INDUSTRY_NEWS]: {
      layers: [
        MapLayer.INDUSTRY_NEWS,
        MapLayer.INDUSTRY_NEWS_CLUSTERS,
        MapLayer.INDUSTRY_NEWS_CLUSTER_COUNT,
      ],
      layerGroups: [],
      disabled: industryNews === null,
      onToggleOn: () => {
        if (industryNews && isLayerSourceEmpty(MapLayer.INDUSTRY_NEWS)) {
          setIndustryNewsFeatures(MapLayer.INDUSTRY_NEWS, industryNews);
        }
      },
    },
    [EMenuItems.SAVE_SETUP]: { layers: [], layerGroups: [], disabled: true },
    [EMenuItems.PRIVACY_POLICY]: {
      layers: [],
      layerGroups: [],
      disabled: true,
    },
    [EMenuItems.COUNTRIES]: { layers: [], layerGroups: [], disabled: false },
    [EMenuItems.DOCUMENTS]: { layers: [], layerGroups: [], disabled: true },
    // Add First Call Layers
    [EMenuItems.TOOLS]: {
      layers: [],
      layerGroups: [MapGroupLayer.RANGE_RINGS],
      disabled: rangeRings.points.length === 0,
    },
    [EMenuItems.VPS_PORTS]: { layers: [], layerGroups: [], disabled: true },
    [EMenuItems.NOTHING_SELECTED]: {
      layers: [],
      layerGroups: [],
      disabled: false,
    },
  };

  return layerConfig;
}

export default useMainMenuLayerConfig;
