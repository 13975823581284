import MapHelpers from '../../map.utils';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerIcon from '../map-layer-icon';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';

// A property added when cluster: true on a GeoJSON Source.
const GLOBAL_ICON_SCALE_FACTOR = 1.2;
const POINT_COUNT = 'point_count';

function setPortsLayer(layerId: MapLayer | string, metadata?: {}) {
  const clusterLayerId =
    layerId === MapLayer.PORTS
      ? MapLayer.PORT_CLUSTERS
      : MapLayer.ROUTES_PORT_CLUSTERS;
  const clusterCountLayerId =
    layerId === MapLayer.PORTS
      ? MapLayer.PORT_CLUSTER_COUNT
      : MapLayer.ROUTES_PORT_CLUSTER_COUNT;

  return new Promise((resolve) => {
    addSimpleGeoJsonSource(layerId, true);

    MapHelpers.addLayer({
      id: clusterLayerId,
      type: 'circle',
      source: layerId,
      filter: ['has', POINT_COUNT],
      metadata,
      layout: {
        visibility: MapLayerVisibility.NOT_VISIBLE,
      },
      paint: {
        'circle-color': '#f063d7',
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        'circle-radius': ['step', ['get', POINT_COUNT], 10, 5, 15, 10, 20],
      },
    });

    MapHelpers.addLayer({
      id: clusterCountLayerId,
      type: 'symbol',
      source: layerId,
      filter: ['has', POINT_COUNT],
      metadata,
      layout: {
        visibility: MapLayerVisibility.NOT_VISIBLE,
        'text-field': ['get', 'point_count_abbreviated'],
        'text-allow-overlap': true,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
      paint: {
        'text-color': '#000000',
      },
    });

    MapHelpers.addLayer({
      id: layerId,
      type: 'symbol',
      source: layerId,
      metadata,
      filter: ['!', ['has', POINT_COUNT]],
      layout: {
        visibility: MapLayerVisibility.NOT_VISIBLE,
        'icon-image': [
          'case',
          ['==', ['get', 'selected'], true],
          [
            'case',
            ['==', ['get', 'cruise'], true],
            MapLayerIcon.PORT.SELECTED_CRUISE_RI, // Icon for SELECTED_CRUISE_RI when selected and cruise is true
            ['==', ['get', 'ri'], true],
            MapLayerIcon.PORT.SELECTED_RI,
            // Icon for SELECTED_RI when selected and ri is true
            MapLayerIcon.PORT.SELECTED, // Icon for SELECTED when selected and ri is false
          ],
          ['==', ['get', 'inland'], true],
          // INLAND_RI currently the same as DEFAULT_RI
          MapLayerIcon.PORT.INLAND_RI, // Icon for INLAND_RI when inland is true
          ['==', ['get', 'cruise'], true],
          MapLayerIcon.PORT.CRUISE_RI, // Icon for CRUISE_RI when cruise is false
          ['==', ['get', 'ri'], true],
          MapLayerIcon.PORT.DEFAULT_RI, // Icon for DEFAULT_RI when ri is true
          MapLayerIcon.PORT.DEFAULT, // Icon for DEFAULT when none of the conditions are true
        ],
        'icon-allow-overlap': true,
        'icon-size': [
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#interpolate
          'interpolate',
          ['linear'],
          ['zoom'],
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#camera-expressions
          5,
          // scale based on zoom level but also the harbour size
          0.7 * GLOBAL_ICON_SCALE_FACTOR,
          8,
          0.8 * GLOBAL_ICON_SCALE_FACTOR,
          16,
          1.2 * GLOBAL_ICON_SCALE_FACTOR,
        ],
      },
    });

    resolve(MapHelpers);
  });
}

export default setPortsLayer;
