import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
  extractPortThreatTypesFromFilter,
  fetchPortThreats,
  getPorts,
} from '../../api/risk-intelligence/ports';
import Breadcrumb from '../../common-components/breadcrumb/breadcrumb';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import ErrorSnackbar from '../../common-components/error-snackbar/error-snackbar';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import PanelTitle from '../../common-components/panel/panel-title';
import { useAppDispatch, useAppSelector } from '../../hooks';
import FilterablePorts from './filterable-ports/filterable-ports';
import { setPortsFilters } from './world-ports-panel.slice';

import { ReduxStatesToVerify, useEnsureReduxLoaded } from '../../api';
import useAccessControl from '../../hooks/access-control/useAccessControl';
import { MapGroupLayers } from '../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../map/map.utils';
import {
  setLoading,
  setSelectedPort,
  setSelectedPortId,
} from '../../state/ports/ports.slice';
import './world-ports-panel.scss';

function WorldPortsPanel() {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.portsPanel);
  const { ports, loading, error, selectedPort, selectedPortId } =
    useAppSelector((state) => state.ports);
  const userToken = useAppSelector((state) => state.user.idToken);
  const { canAccessPorts, canAccessRiCountries } = useAccessControl();

  const [entityError, setEntityError] = useState<boolean>(false);

  // RI ports that do not match a WPI port need countries to be loaded
  useEnsureReduxLoaded([
    ReduxStatesToVerify.PORTS,
    ...(canAccessRiCountries ? [ReduxStatesToVerify.COUNTRIES] : []),
  ]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEntityError(false);
  };

  const loadPorts = useCallback(async () => {
    getPorts(userToken, extractPortThreatTypesFromFilter(filters));
  }, [userToken, filters.threatType]);

  useEffect(() => {
    if (ports && !loading) {
      loadPorts();
    }
  }, [loadPorts, loading]);

  useEffect(() => {
    if (canAccessPorts.riskIntelligence && filters.threatType.length === 0) {
      fetchPortThreats();
    }
    if (!ports && !loading) {
      dispatch(setLoading(true));
      loadPorts();
    }
  }, []);

  useEnsureReduxLoaded([ReduxStatesToVerify.PORTS], {
    before: () => {
      dispatch(setLoading(true));
    },
    after: () => {
      dispatch(setLoading(false));
      MapGroupLayers.PORTS.forEach((layer) => {
        MapHelpers.setLayerVisibility(layer, true);
      });
    },
  });

  useEffect(() => {
    // if selectedPortId has been set, but selectedPort has not
    // a port was called from a different route that clicking it on the map or on the ports list.
    // this means the ports may not yet be loaded
    // as soon as the ports are loaded if will succeed and try to populate selected port
    if (ports && selectedPortId && !selectedPort) {
      const portToSelect =
        ports.find(
          (port) =>
            port.ri?.id === Number(selectedPortId) ||
            port.ri?.unlocode?.replace(/\s/, '').toLowerCase() ===
              selectedPortId.toString().toLowerCase()
        ) ||
        ports.find(
          (port) =>
            port.UNLOCODE?.replace(/\s/, '').toLowerCase() ===
            selectedPortId.toString().toLowerCase()
        );
      if (portToSelect) {
        dispatch(setSelectedPort(portToSelect));
      } else {
        setEntityError(true);
      }
      dispatch(setSelectedPortId(null));
    }
  }, [ports, selectedPort, selectedPortId]);

  const selectedPortIdSetButNotSelectedPort =
    (!ports || !selectedPort) && selectedPortId;

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
      }}
    >
      <PanelTitle>
        {filters.open ? (
          <>
            <IconButton
              size="small"
              onClick={() => {
                dispatch(setPortsFilters({ open: false }));
              }}
            >
              <ArrowBack />
            </IconButton>
            Filter
          </>
        ) : (
          'Ports'
        )}
      </PanelTitle>
      <Breadcrumb currentPanel="portList" />

      <Box
        sx={{
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          height: '100%',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        {(loading || selectedPortIdSetButNotSelectedPort) && <LoadingPanel />}
        {!loading && error && (
          <ErrorPanel message="Error: Unable to fetch ports data, please try again later..." />
        )}
        {!loading && !error && ports && <FilterablePorts ports={ports} />}
        <ErrorSnackbar
          open={entityError}
          onClose={handleSnackbarClose}
          message="Unable to find port id provided in URL"
        />
      </Box>
    </Box>
  );
}

export default WorldPortsPanel;
